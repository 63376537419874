<script setup lang="ts">
</script>

<template>
    <div class="border-r border-b border-dashed border-black print:border-none">
        <div id="print-area">
            <slot />
        </div>
    </div>
</template>

<style>
/* Tell PDF creator to honor background-color and other instructions it might discard to save ink */
html {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

/* Define page size. */
body {
    margin: 0;
    padding: 0;
    width: 29.7cm;
    height: 21cm;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
}

* {
    box-sizing: content-box;
}

/* Printable area */
#print-area {
    position: relative;
    top: 0;
    left: 0;
    width: 29.7cm;
    height: 21cm;
    font-size: 10pt;
    overflow: hidden;
}

strong {
    font-weight: 900;
}

/* Make images non-interactable so they don't interfere with contenteditable */
img {
    pointer-events: none;
}
</style>
